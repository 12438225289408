a, .app-content {
    font-size: 14px !important;
}

.MuiInputBase-input {
    height: 40px !important;
}

h4, .h4 {
    font-size: 15px !important;
}

.deznav .metismenu > li > a {
    padding: 10px 50px !important;
}

.header {
    height: 100px !important;
}

.content-body {
    padding-top: 4.5rem !important;
}

.content-body .container-fluid, .content-body .container-sm, .content-body .container-md, .content-body .container-lg, .content-body .container-xl {
    padding-right: 10px;
    padding-left: 10px;
}

.card-body {
    padding: 15px !important;
}