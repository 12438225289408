.map-wrapper{
  height: 100vh;
  width: 100vw;
}

.map-container {
    height: 100%;
    width: 100%;
}

.image-div {
    margin: 10px;
    border-radius: 10px;
    width: 100px;
    height: 50px;
}