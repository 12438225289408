.rapports-select .select-search {
    width: 70%;
}

.content-div {
    border-top: 1px solid lightgrey;
    padding-top: 20px;
}

.content-div h3 {
    text-align: center;
    text-decoration: underline;
}