.notif-clignote  {
    animation-duration: 3s;
    animation-name: clignoter;
    animation-iteration-count: infinite;
    transition: none;
 }
 @keyframes clignoter {
   0%   { opacity:1; }
   40%   {opacity:0; }
   100% { opacity:1; }
 }
 
 .blink {
    animation: blink-animation 1s steps(5, start) infinite;
    -webkit-animation: blink-animation 1s steps(5, start) infinite;
  }
  @keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }
  @-webkit-keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }

  .header {
    background-image: 
    /* top, transparent red */
    linear-gradient(4deg, rgba(2,0,36,.8) 0%, rgba(9,9,121,.8) 35%, rgba(0,212,255,.8) 100%),
    /* your image */
    url(../../../images/images.jpg) !important;
  }

  .hamburger .lines {
      background-color: white !important;
      display: block;
      height: 3px;
      border-radius: 3px;
      margin-top: 6px;
      margin-bottom: 6px;
      margin-left: auto;
      transition: all 0.3s ease-in-out;
  }

  .header-right .header-profile > a.nav-link {
    padding: 0;
    background: rgba(255, 255, 255, 0.5) !important;
    border-radius: 4rem;
    display: flex;
    align-items: center;
}