.p-datatable-scrollable-table>.p-datatable-thead {
    z-index: 0 !important;
}

.form-control {
    height: 40px !important;
}

.modal-btn {
    padding: 10px 20px !important;
}